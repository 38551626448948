
export class KeyModality{

    // TYPE DATE
    static TYPE_FIX = 'fix';
    static TYPE_SLIDE = 'slide';
    static TYPE_FLEXIBLE = 'flexible';
    static TYPE_FREE = 'free';

  static KEY_FEE_TYPE = [
      { title: 'Aucun', value: 'none' },
      { title: 'Montant', value: 'amount' },
      { title: 'Pourcentage', value: 'purcentage' }
    ]

  static MODALITY_TYPE_DATE = [
      { title: 'Date fixe', value: this.TYPE_FIX },
      { title: 'Mois glissant', value: this.TYPE_SLIDE },
      { title: "A fixer entre la structure et l'élève", value: this.TYPE_FLEXIBLE },
      { title: 'Cours gratuit', value: this.TYPE_FREE },
    ]

  // RESTRICTION
  static readonly UNRESTRICTED = 'unrestricted';
  static readonly RESTRICTED_ALL_USERS = 'restricted-all-users';
  static readonly RESTRICTED_SELECTED_USERS = 'restricted-selected-users';

  static MODALITY_RESTRICTION = [
      { title: 'Non restreint', value: this.UNRESTRICTED },
      { title: 'Restreint - Visible pour tous', value: this.RESTRICTED_ALL_USERS },
      {
        title: 'Restreint - Visible pour les personnes autorisées',
        value: this.RESTRICTED_SELECTED_USERS,
      }
    ]


  // RISK TYPE
  static readonly RISK_TYPE_NEXT_MONTH = 'next-month';
  static readonly RISK_TYPE_SHARE = 'share';
  static readonly RISK_TYPE_DELETE = 'delete';

  static RISK_TYPE  = [
      {
        title: 'Reporter le premier prélèvement au même jour du mois suivant.',
        value: this.RISK_TYPE_NEXT_MONTH,
      },
      {
        title: 'Répartir le montant du prélèvement sur les autres prélèvements à venir.',
        value: this.RISK_TYPE_SHARE,
      },
      {
        title: "Supprimer le prélèvement dont la date est passée, ce qui a pour effet de diminuer le montant total de l'inscription.",
        value: this.RISK_TYPE_DELETE,
      }
    ]

  // PAYMENT TYPE
  static readonly TYPE_NOW = 'now';
  static readonly TYPE_DELAYED = 'delayed';
  static readonly TYPE_BY_PAYMENT_DATE = 'by-payment-date';
  static readonly TYPE_BY_START_DATE = 'by-start-date';
  static readonly TYPE_BY_BEFORE_START_DATE = 'by-before-start-date';

  static DATE_1_TYPE  = [
      { title: 'Immédiat', value: this.TYPE_NOW },
      { title: 'Différé', value: this.TYPE_DELAYED },
      { title: 'Par rapport à la date d’achat', value: this.TYPE_BY_PAYMENT_DATE },
      {
        title: 'X jours après à la prochaine séance',
        value: this.TYPE_BY_START_DATE,
      },
      {
        title: 'X jours avant à la prochaine séance',
        value: this.TYPE_BY_BEFORE_START_DATE,
      }
    ];

}